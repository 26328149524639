html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-white);
}

@media only screen and (max-width: 1650px) {
  html {
    font-size: 40%;
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%; /* will ensure that the text doesn't get size adjusted automatically by the browser. */
  scrollbar-width: thin;
  background: var(--color-bg);
}

@keyframes skeleton-loading {
  0% {
    background-color: #757575;
  }
  100% {
    background-color: #797979;
  }
}

wave {
  cursor: pointer !important;
}

textarea,
a,
button,
span,
div,
input {
  font-family: var(--font-primary);
}

.custom_modal_container .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  background-color: var(--browse-background-color);
  border: 1px solid white !important;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 18px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}
